import gql from 'graphql-tag'
import ProjectAllFields from './fragments/ProjectAllFields'

export default gql`
  query getProject ($id: Int, $random_key: String) {
    project (id: $id, random_key: $random_key) {
      ...ProjectAllFields
    }
  }
  ${ProjectAllFields}
`
