<template>
  <div
    :class="{ 'd-flex flex-wrap': dense }"
  >
    <span
      v-if="_.isEmpty(project.slots)"
      class="caption text--disabled"
    >
      No participant slots
    </span>
    <div
      v-for="(slot, index) in project.slots"
      :key="slot.id"
    >
      <v-tooltip
        v-if="slot.user"
        :disabled="!dense"
        bottom
      >
        <template v-slot:activator="{ on }">
          <div
            class="d-flex align-center mb-1"
            v-on="on"
          >
            <ApolloMutation
              :mutation="require('@/gql/toggleProjectParticipantSlotUser').default"
              :variables="{ slot_id: slot.id }"
              @error="error => $notify({ group: 'dashboard', type: 'error', title: error.message })"
              @done="$notify({ group: 'dashboard', type: 'success', title: 'Successfully updated participant slot' })"
            >
              <template v-slot="{ mutate, loading }">
                <v-btn
                  class="ma-0"
                  :class="{ 'mr-2': index !== project.slots.length - 1 }"
                  :disabled="slot.user.id !== claims.sub || !project.is_enabled || !event.is_active"
                  :loading="loading"
                  color="primary"
                  depressed
                  x-small
                  fab
                  @click="mutate()"
                >
                  <v-avatar
                    :size="28"
                  >
                    <img
                      :src="slot.user.slack_picture"
                      :alt="slot.user.name"
                    >
                  </v-avatar>
                </v-btn>
              </template>
            </ApolloMutation>
            <span v-if="!dense">
              {{ slot.user.name }} as <strong>{{ slot.name }}</strong>
            </span>
          </div>
        </template>
        <span>{{ slot.user.name }} as {{ slot.name }}</span>
      </v-tooltip>
      <v-tooltip
        v-else
        :disabled="!dense"
        bottom
      >
        <template v-slot:activator="{ on }">
          <div
            class="d-flex align-center mb-1"
            v-on="on"
          >
            <ApolloMutation
              :mutation="require('@/gql/toggleProjectParticipantSlotUser').default"
              :variables="{ slot_id: slot.id }"
              @error="error => $notify({ group: 'dashboard', type: 'error', title: error.message })"
              @done="$notify({ group: 'dashboard', type: 'success', title: 'Successfully updated participant slot' })"
            >
              <template v-slot="{ mutate, loading }">
                <v-btn
                  class="ma-0"
                  :class="{ 'mr-2': dense ? index < project.slots.length - 1 : true }"
                  :disabled="currentUserIsParticipant || loading || !project.is_enabled || !event.is_active"
                  :loading="loading"
                  depressed
                  x-small
                  fab
                  @click="mutate()"
                >
                  <v-icon
                    small
                  >
                    mdi-account-plus
                  </v-icon>
                </v-btn>
              </template>
            </ApolloMutation>
            <span v-if="!dense">
              Looking for <strong>{{ slot.name }}</strong>
            </span>
          </div>
        </template>
        <span>Available slot for {{ slot.name }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProjectCardParticipantSlots',
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapState('user', ['claims']),
    currentUserParticipant () {
      const takenSlots = this.project.slots.filter(slot => slot.user)
      return this._.find(takenSlots, participantSlot => participantSlot.user.id === this.claims.sub)
    },
    currentUserIsParticipant () {
      return !!this.currentUserParticipant
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
