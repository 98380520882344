import gql from 'graphql-tag'
import UserAllFields from './fragments/UserAllFields'

export default gql`
  mutation updateProjectLike ($project_id: Int!, $is_enabled: Boolean!) {
    updateProjectLike (project_id: $project_id, is_enabled: $is_enabled) {
      id
      user {
        ...UserAllFields
      }
    }
  }
  ${UserAllFields}
`
