import gql from 'graphql-tag'
import CommentAllFields from './fragments/CommentAllFields'

export default gql`
  subscription projectCommentUpdated ($project_id: Int!) {
    projectCommentUpdated (project_id: $project_id) {
      ...CommentAllFields
    }
  }
  ${CommentAllFields}
`
