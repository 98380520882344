import gql from 'graphql-tag'
import CommentAllFields from './fragments/CommentAllFields'

export default gql`
  query getProjectComments ($project_id: Int!) {
    comments (project_id: $project_id) {
      ...CommentAllFields
    }
  }
  ${CommentAllFields}
`
