import gql from 'graphql-tag'
import UserAllFields from './UserAllFields'
import TagAllFields from './TagAllFields'
import CommentMinimalFields from './CommentMinimalFields'

export default gql`
  fragment ProjectAllFields on Project {
    id
    random_key
    name
    description
    markdown_description
    cover_image_url
    document
    github_repository
    slack_channel
    video
    is_enabled
    is_draft
    created
    updated
    owner {
      ...UserAllFields
    }
    likes {
      id
      user {
        ...UserAllFields
      }
    }
    slots {
      id
      name
      user {
        ...UserAllFields
      }
    }
    tags {
      id
      tag {
        ...TagAllFields
      }
      rating
      is_enabled
    }
    comments {
      ...CommentMinimalFields
    }
  }
  ${UserAllFields}
  ${TagAllFields}
  ${CommentMinimalFields}
`
