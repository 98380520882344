import gql from 'graphql-tag'
import CommentAllFields from './fragments/CommentAllFields'

export default gql`
  mutation createProjectComment ($project_id: Int!, $category_id: Int, $value: String!, $tags: [Int]) {
    createProjectComment (project_id: $project_id, category_id: $category_id, value: $value, tags: $tags) {
      ...CommentAllFields
    }
  }
  ${CommentAllFields}
`
