import gql from 'graphql-tag'
import UserAllFields from './UserAllFields'
import CommentCategoryAllFields from './CommentCategoryAllFields'
import CommentResolutionAllFields from './CommentResolutionAllFields'

export default gql`
  fragment CommentMinimalFields on ProjectComment {
    id
    category {
      ...CommentCategoryAllFields
    }
    resolution {
      ...CommentResolutionAllFields
    }
    resolution_note
    user {
      ...UserAllFields
    }
    value
  }
  ${UserAllFields}
  ${CommentCategoryAllFields}
  ${CommentResolutionAllFields}
`
