import gql from 'graphql-tag'
import UserAllFields from './fragments/UserAllFields'

export default gql`
  mutation toggleProjectParticipantSlotUser ($slot_id: Int!) {
    toggleProjectParticipantSlotUser (slot_id: $slot_id) {
      id
      name
      user {
        ...UserAllFields
      }
    }
  }
  ${UserAllFields}
`
