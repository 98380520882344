<template>
  <div
    class="fill-height"
    :style="{ 'min-height': '100%' }"
  >
    <template v-if="event && project">
      <v-row
        :style="{ 'min-height': '100%' }"
        dense
      >
        <v-col
          class="pa-4"
          :cols="12"
          :md="true"
        >
          <!-- LINK BUTTONS -->
          <v-row dense>
            <v-col
              class="pt-0"
              :cols="12"
            >
              <div class="text-h5">
                Links
              </div>
            </v-col>
            <v-col v-if="event.show_document_field">
              <v-tooltip
                :open-delay="500"
                :max-width="500"
                bottom
              >
                <template
                  v-slot:activator="{ on }"
                >
                  <v-btn
                    class="mr-1"
                    :color="project.document ? 'primary' : undefined"
                    :disabled="!project.document || !project.is_enabled"
                    depressed
                    block
                    small
                    @click.prevent="openURL(project.document)"
                    v-on="on"
                  >
                    <v-icon class="mr-2">
                      mdi-file-document
                    </v-icon>
                    Document
                  </v-btn>
                </template>
                <span>{{ project.document }}</span>
              </v-tooltip>
            </v-col>
            <v-col v-if="event.show_github_repository_field">
              <v-tooltip
                :open-delay="500"
                :max-width="500"
                bottom
              >
                <template
                  v-slot:activator="{ on }"
                >
                  <v-btn
                    class="mr-1"
                    :color="project.github_repository ? 'primary' : undefined"
                    :disabled="!project.github_repository || !project.is_enabled"
                    depressed
                    block
                    small
                    @click.prevent="openURL(project.github_repository)"
                    v-on="on"
                  >
                    <v-icon class="mr-2">
                      mdi-github
                    </v-icon>
                    GitHub Repository
                  </v-btn>
                </template>
                <span>{{ project.github_repository }}</span>
              </v-tooltip>
            </v-col>
            <v-col v-if="event.show_slack_channel_field">
              <v-tooltip
                :open-delay="500"
                :max-width="500"
                bottom
              >
                <template
                  v-slot:activator="{ on }"
                >
                  <v-btn
                    class="mr-1"
                    :color="project.slack_channel ? 'primary' : undefined"
                    :disabled="!project.slack_channel || !project.is_enabled"
                    depressed
                    block
                    small
                    @click.prevent="openURL(`https://slack.com/app_redirect?channel=${project.slack_channel}`)"
                    v-on="on"
                  >
                    <v-icon class="mr-2">
                      mdi-slack
                    </v-icon>
                    Slack Channel
                  </v-btn>
                </template>
                <span>Open <strong>{{ `#${project.slack_channel}` }}</strong> in Slack</span>
              </v-tooltip>
            </v-col>
            <v-col v-if="event.show_video_field">
              <v-tooltip
                :open-delay="500"
                :max-width="500"
                bottom
              >
                <template
                  v-slot:activator="{ on }"
                >
                  <v-btn
                    class="mr-1"
                    :color="project.video ? 'primary' : undefined"
                    :disabled="!project.video || !project.is_enabled"
                    depressed
                    block
                    small
                    @click.prevent="openURL(project.video)"
                    v-on="on"
                  >
                    <v-icon class="mr-2">
                      mdi-camera
                    </v-icon>
                    Video
                  </v-btn>
                </template>
                <span>{{ project.video }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <!-- TAGS -->
          <v-row dense>
            <v-col>
              <div class="text-h5 mb-2">
                Tags
              </div>
              <div>
                <span
                  v-if="_.isEmpty(project.tags)"
                  class="text--disabled"
                >
                  No tags
                </span>
                <v-row dense>
                  <v-col
                    v-for="tag in project.tags"
                    :key="tag.id"
                    :cols="12"
                    :xl="4"
                    :lg="6"
                  >
                    <v-sheet outlined>
                      <div class="d-flex align-center justify-space-between pa-2">
                        <div
                          v-line-clamp="1"
                          class="d-block text-truncate"
                        >
                          <v-tooltip
                            :disabled="!tag.tag.description"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ tag.tag.display_name }}
                              </span>
                            </template>
                            <span>{{ tag.tag.description }}</span>
                          </v-tooltip>
                        </div>
                        <ApolloMutation
                          :mutation="require('@/gql/updateProjectTag').default"
                          @error="error => $notify({ group: 'dashboard', type: 'error', title: error.message })"
                          @done="$notify({ group: 'dashboard', type: 'success', title: 'Successfully updated rating' })"
                        >
                          <template v-slot="{ mutate, loading }">
                            <v-rating
                              empty-icon="mdi-star-outline"
                              full-icon="mdi-star"
                              background-color="grey"
                              :color="loading || (!currentUserIsOwner) ? 'grey' : 'primary'"
                              :length="5"
                              :size="12"
                              :value="tag.rating"
                              :readonly="loading || (!currentUserIsOwner)"
                              hover
                              dense
                              @input="rating => mutate({ variables: { id: tag.id, value: { rating } } })"
                            />
                          </template>
                        </ApolloMutation>
                      </div>
                    </v-sheet>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <!-- PARTICIPANTS -->
          <v-divider class="my-2" />
          <v-row dense>
            <v-col>
              <div class="text-h5 mb-2">
                Participants
              </div>
              <project-card-participant-slots
                :event="event"
                :project="project"
              />
            </v-col>
          </v-row>
          <!-- LIKES -->
          <v-divider class="my-2" />
          <v-row dense>
            <v-col>
              <div class="d-flex align-center justify-space-between text-h5 mb-2">
                Likes
                <v-btn
                  :disabled="loading.like || !project.is_enabled || !event.is_active"
                  :loading="loading.like"
                  x-small
                  text
                  fab
                  @click="likeMutation"
                >
                  <v-icon :color="currentUserLike ? 'primary' : 'grey'">
                    mdi-thumb-up
                  </v-icon>
                </v-btn>
              </div>
              <div class="d-flex align-center justify-start">
                <span
                  v-if="_.isEmpty(project.likes)"
                  class="text--disabled"
                >
                  No likes yet
                </span>
                <slack-avatar
                  v-for="like in project.likes"
                  :key="like.id"
                  class="mr-1"
                  :user="like.user"
                  :size="32"
                />
              </div>
            </v-col>
          </v-row>
          <!-- DESCRIPTION -->
          <v-divider class="my-2" />
          <v-row dense>
            <v-col>
              <div class="text-h5 mb-2">
                Description
              </div>
              <span
                v-if="!project.markdown_description && !project.description"
                class="text--disabled"
              >
                No description yet
              </span>
              <div v-else>
                <div
                  v-if="project.description"
                  class="mb-4"
                  :style="{ 'white-space': 'pre-line' }"
                >
                  {{ project.description }}
                </div>
                <vue-markdown
                  v-if="project.markdown_description"
                  class="markdown-body"
                  :source="project.markdown_description"
                  :anchor-attributes="{ target: '_blank' }"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-divider
          v-if="$vuetify.breakpoint.lgAndUp"
          vertical
        />
        <v-col
          class="pa-4"
          :cols="12"
          :md="true"
        >
          <v-row dense>
            <v-col
              class="pt-0"
              :cols="12"
            >
              <div class="text-h5">
                Comments
              </div>
            </v-col>
            <v-col>
              <project-comments
                :event="event"
                :project="project"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <v-overlay
      v-else
      color="white"
      :opacity="dialogMode ? 0 : 0.5"
      z-index="10"
      :absolute="dialogMode"
    >
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      />
    </v-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SlackAvatar from '@/views/dashboard/components/core/SlackAvatar'
import ProjectCardParticipantSlots from '@/views/dashboard/components/core/ProjectCardParticipantSlots'
import ProjectComments from '@/views/dashboard/components/core/ProjectComments'
export default {
  name: 'ProjectView',
  components: { SlackAvatar, ProjectCardParticipantSlots, ProjectComments },
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    },
    dialogMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: {
      like: false
    }
  }),
  computed: {
    ...mapState('user', ['claims']),
    currentUserIsOwner () {
      return this.project.owner.id === this.claims.sub
    },
    currentUserParticipant () {
      const takenSlots = this.project.slots.filter(slot => slot.user)
      return this._.find(takenSlots, participantSlot => participantSlot.user.id === this.claims.sub)
    },
    currentUserIsParticipant () {
      return !!this.currentUserParticipant
    },
    currentUserLike () {
      return this._.find(this.project.likes, like => like.user.id === this.claims.sub)
    }
  },
  methods: {
    ...mapActions('main', ['SET_TITLE']),
    async likeMutation () {
      this.loading.like = true
      const currentValue = !!this.currentUserLike
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateProjectLike').default,
          variables: {
            project_id: this.project.id,
            is_enabled: !currentValue
          },
          update: (store, { data: { updateProjectLike } }) => {
            const data = store.readQuery({ query: require('@/gql/getProject').default, variables: { random_key: this.$route.params.projectRandomKey || this.projectItem.random_key } })
            const currentProject = data.project
            if (!currentProject) return
            currentProject.likes = updateProjectLike
            store.writeQuery({ query: require('@/gql/getProject').default, variables: { random_key: this.$route.params.projectRandomKey || this.projectItem.random_key }, data })
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: `Successfully ${!currentValue ? 'liked' : 'removed like'}`
        })
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.like = false
    },
    openURL (url) {
      if (url) {
        const win = window.open(url, '_blank')
        win.focus()
      }
    }
  }
}
</script>
