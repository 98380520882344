import gql from 'graphql-tag'
import TagAllFields from './fragments/TagAllFields'

export default gql`
  mutation updateProjectTag ($id: Int!, $value: ProjectTagInput!) {
    updateProjectTag (id: $id, value: $value) {
      id
      tag {
        ...TagAllFields
      }
      rating
      is_enabled
    }
  }
  ${TagAllFields}
`
